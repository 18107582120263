<template>
  <v-app style="background: #eaf5ff">
    <preloader style="z-index: 100000" />
    <transition name="slide-fade">
      <div class="menu-display" v-if="showMenu">
        <ul>
          <li v-for="(i, index) in btn" :key="index" @click="showMenu = false">
            <router-link :to="i.target">
              <v-icon>{{ i.icon }}</v-icon> {{ i.text }}
            </router-link>
          </li>
        </ul>
      </div>
    </transition>
    <v-snackbar v-model="message" absolute right bottom dark color="#fff" elevation="24" small
      style="position: fixed; z-index: 9999" :timeout="-1">
      El sitio mostrado se encuentra en construcción (desarrollo). Los datos que
      se muestran son ficticios, elaborados solamente para pruebas de
      funcionalidad y demostración.
      <template v-slot:action="{ attrs }">
        <v-btn color="#fff" text v-bind="attrs" @click="message = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <nav>
      <v-btn elevation="0" fab small color="#fff" v-if="btnBack" class="btn-back"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="logo-p shadow" @click="$router.push('/')">
        <img src="@/assets/logo-01.svg" />
        <img src="@/assets/campaña.svg" />
      </div>
      <v-btn elevation="0" fab small class="menu-mobile" @click="showMenu = !showMenu">
        <v-icon>{{ showMenu ? "mdi-window-close" : "mdi-menu" }}</v-icon>
      </v-btn>
    </nav>
    <div class="wrap-menu" v-if="menu">
      <div class="menu shadow">
        <v-btn fab small :color="activeBtn.target === i.target ? 'primary' : '#fff'" elevation="0"
          v-for="(i, index) in btn" :key="index" class="btn-menu" @click="toGo(i)">
          <v-icon small :color="activeBtn.target === i.target ? '#fff' : 'primary'">{{ i.icon }}</v-icon>
        </v-btn>
      </div>
    </div>
    <v-main class="main-main">
      <transition-page>
        <router-view />
      </transition-page>
    </v-main>
  </v-app>
</template>

<script>
import TransitionPage from "@/components/TransitionPage";
import preloader from "@/components/preloader.vue";

export default {
  name: "App",
  components: {
    TransitionPage,
    preloader,
  },
  data() {
    return {
      message: true,
      isMobile: window.screen.width < 500,
      showMenu: false,
      btnBack: false,
      menu: false,
      activeBtn: {},
      btn: [
        { target: "/", icon: "mdi-view-dashboard", text: "Inicio" },
        { target: "/proyects", icon: "mdi-map-marker-multiple-outline", text: "Mapa" },
      ],
    };
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    toGo(i) {
      this.activeBtn = i;
      this.$router.push(i.target);
    },
  },
  watch: {
    $route(to) {
      this.btnBack = to.meta.btnBack;
      this.menu = to.meta.menu;
      this.activeBtn = { target: to.meta.path };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.btnBack = this.$route.meta.btnBack;
      this.menu = this.$route.meta.menu;
      this.activeBtn.target = this.$route.meta.path;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";

.btn-back {
  position: fixed;
  left: 10px;
  top: 6px;
  z-index: 10000 !important;
  cursor: pointer;
}

.main-main {
  padding-left: 100px !important;
  height: 100%;

  @include responsive(mobile) {
    width: 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  @include responsive(ipad) {
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

nav {
  position: fixed;
  top: 0;
  width: 100vw;
  padding-left: 20px;
  padding-right: 20px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 800;
  text-align: center;

  .logo-p {
    cursor: pointer;
    gap: 20px;
    background-color: #fff;
    width: 280px;
    border-radius: 0 0 20px 20px;
    position: absolute;
    margin: auto;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 4px 8px;

    @include responsive(mobile) {
      width: 100%;
    }

    img {
      width: 80px;
    }
  }
}

body {
  background-color: #eaf5ff;
}

.wrap-menu {
  @include responsive(mobile) {
    display: none;
  }
  @include responsive(small) {
    display: none;
  }
  @include responsive(ipad) {
    display: none;
  }
  @include responsive(ipadPro) {
    display: none;
  }

  height: 50vh;
  width: auto;
  position: fixed;
  left: 20px;
  top: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 700;

  .menu {
    padding: 10px;
    background-color: #fff;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 10000;
  }
}

.btn-menu {
  transition: 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}

.menu-mobile {
  position: absolute;
  top: 7px;
  right: 20px;
  display: none;

  @include responsive(mobile) {
    display: block;
  }
  @include responsive(small) {
    display: block;
  }
  @include responsive(ipad) {
    display: block;
  }
}

.menu-display {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 599;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    font-size: 1.5rem;
    list-style: none;

    li {
      list-style: none;

      a {
        color: #2f2f2f;
      }
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-100vh);
  opacity: 0;
}
</style>
