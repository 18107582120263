import {createVirtualPATH, withBaseName} from "@/environment/virtualPath";

const jsEnv = window._env_;

const VIRTUAL_PATH = createVirtualPATH(jsEnv);

export const env = {
    APP_URL: jsEnv.APP_URL,
    VERSION: jsEnv.VERSION,
    SYSTEM_NAME: jsEnv.SYSTEM_NAME,
    VUE_APP_LA_ENEE: jsEnv.VUE_APP_LA_ENEE,
    BASENAME: withBaseName(VIRTUAL_PATH),
    BASE_URL: VIRTUAL_PATH,
}
