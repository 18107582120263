import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      menu: true,
      path: '/',
      searchShow: true,
      btnBack: false,
      index: 1,
      transitionName: 'zoomIn-SlideOut',
      background: true,
    },
  },
  
  {
    path: '/proyects',
    name: 'proyects',
    component: function () {
      return import('../views/proyects.vue')
    },
    meta: {
      menu: true,
      path: '/proyects',
      searchShow: true,
      btnBack: true,
      index: 2,
      transitionName: 'zoomIn-SlideOut',
      background: false,
    },
  },
  {
    path: '/infoProyect/:_id',
    name: 'infoProyect',
    component: function () {
      return import('../views/infoProyect.vue')
    },
    params: true,
    props: true,
    meta: {
      path: '/infoProyect',
      menu: true,
      searchShow: true,
      btnBack: true,
      index: 2,
      transitionName: 'zoomIn-SlideOut',
      background: false,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
