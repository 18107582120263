import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
Vue.use(Vuex);
const vuexPersist = new VuexPersist({
  key: "inversionPublica",
  storage: window.localStorage,
  modules: [
    "selectProyect",
    "allProyectsGlobal"
  ],
});

export default new Vuex.Store({
  estado: {
    selectProyect:{},
    allProyectsGlobal:[],
    SnackbarAlert: {
      text: "",
      timeout: 2000,
      btn_closed: false,
      icon: false,
      type: "",
    },
  },
  mutations: {
    AlertSnackbar(estado, config) {
      estado.SnackbarAlert = config;
      return estado.SnackbarAlert;
    },
    setProyects(estado, proyects) {
      estado.allProyectsGlobal = proyects;
      return estado.allProyectsGlobal;
    },
    selectProyect(estado, proyect) {
      estado.selectProyect = proyect;
      return estado.selectProyect;
    },

  },
  actions: {
    Alert_: ({ commit, estado }, config) => {
      commit("AlertSnackbar", config);
    },
    setProyects_: ({ commit, estado }, proyects) => {
      commit("setProyects", proyects);
    },
    selectProyect_: ({ commit, estado }, proyects) => {
      commit("selectProyect", proyects);
    },

  },
  getters: {
    Alert_: (estado) => {
      return estado.SnackbarAlert;
    },

  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
