import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/lib/locale/es";
import "vuetify/dist/vuetify.min.css";
Vue.use(Vuetify);
Vuetify.config.silent = true;
export default new Vuetify({
  theme: {
    dark: false,
    background:'#EAF5FF',
    options: {
      customProperties: true,
    },
    themes: {
      background:'#EAF5FF',
      light: {
        primary: "#5FD0DF",
        secondary: "#5AD39A",
        accent: "#c6d600",
        error: "#FF5252",
        info: "#62C3DB",
        success: "#5AD39A",
        warning: "#f69225",
        background:'#EAF5FF'
      },
      dark: {
        primary: "#5FD0DF",
        secondary: "#5170b5",
        accent: "#c6d600",
        error: "#FF5252",
        info: "#62C3DB",
        success: "#5AD39A",
        warning: "#f69225",
        background:'#EAF5FF'
      },
    },
  },
  lang: {
    locales: { es },
    current: "es",
  },
  icons: {
    iconfont: "mdi",
  },
});
