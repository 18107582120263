<template>
  <transition name="fade">
    <div v-if="show" class="loader" :style="`background-color: ${colorBG};`">
      <div class="loader" :style="`background-color: ${colorBG};`">
   <div class="wrap-logo">
    <img src="@/assets/campaña.svg" class="ma-1" />
   </div>

        <div class="disclamer">
          <img src="@/assets/logoGob.svg" class="ma-1" />
          <img src="@/assets/logo-01.svg" class="ma-1" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import Lottie from "./Lottie";

export default {
  name: "Preloader",
  components: {
    // lottie: Lottie,
  },
  data() {
    return {
      colorBG: "#fff",
      color: "",
      isMenu: true,
      anim: null,
      show: true,
      show_lottie: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.show_lottie = true;
        this.showLoader();
      }, 500);
    });
  },
  methods: {
    showLoader() {
      window.onload = setTimeout(() => {
        this.show = false;
      }, 3000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@import "@/_responsive";

.st0 {
  fill: #fff;
}

.st1 {
  fill: none;
  stroke: #f69224;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: naranjas_stroke 2s ease-in forwards;
}

.st2 {
  // fill: #59c6d5;
  fill: none;
  stroke: #59c6d5;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: azul_stroke 2s ease-in forwards;
}

.st3 {
  // fill: #ea2228;

  fill: none;
  stroke: #ea2228;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: red_stroke 2s ease-in forwards;
}

.logo-svg {
  width: 200px;
  height: 200px;
}

.loader {
  width: 100vw;
  height: 100vh;
  z-index: 10000 !important;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  position: fixed;
  flex-direction: column;

  svg {
    width: 400px;

    @include responsive(mobile) {
      width: 250px;
    }
  }

  img {
    width: 400px;

    @include responsive(mobile) {
      width: 300px;
    }
  }

  .lottie-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
  }
}

@include responsive(mobile) {
  .lottie-content {
    width: 75% !important;
  }
}

@include responsive(desktop) {
  .lottie-content {
    width: 250px !important;
    // height: 100px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.by-ocho {
  position: absolute;
  bottom: 15vh;
}

.st0 {
  fill: none;
  stroke: #000000d9;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: circle_stroke 2s ease-in forwards;
}

@keyframes circle_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #000;
  }
}

@keyframes red_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #ea2228;
  }
}

@keyframes azul_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #59c6d5;
  }
}

@keyframes naranjas_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #f69224;
  }
}
.wrap-logo{
  width: 250px;
  img{
    width: 250px;
  }
}
.disclamer {
  position: absolute;
  bottom: 10vh;
  display: flex;
  gap: 10px;

  img {
    width: 150px;
  }
}
</style>
